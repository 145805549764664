<template>
  <div class="wrapper">
    <div class="menu">
      <div class="p-grid">
        <div class="p-col">1</div>
        <div class="p-col">2</div>
        <div class="p-col">3</div>
      </div>
    </div>
    <div class="tool">tool</div>
  </div>
</template>

<style lang="scss">
.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
</style>
